// Dependencies //
import React from "react"
import { Row, Col } from "react-bootstrap"

// Stylesheet //
import Styles from "../sass/components/contact.module.sass"

const Contact = () => {
  const user = "info"
  const domain = "hobbygonlins.no"

  return (
    <section className={Styles["contact"]}>
      <div className={Styles["content-well"]}>
        <h1 className={Styles["contact__heading"]}>Kontakt oss</h1>
        <Row>
          <Col sm={6} md="auto" className={Styles["contact__address"]}>
            <div className={Styles["container"]}>
              <span className="lnr lnr-map-marker"></span>
              <h3>Vestre Strandgate 19B <br/> 4611, Kristiansand</h3>
            </div>
          </Col>
          <Col sm={6} md="auto" className={Styles["contact__mail"]}>
            <div className={Styles["container"]}>
              <span className="lnr lnr-envelope"></span>
              <a href={`mailto:${user}@${domain}`}>{user}&#64;{domain}</a>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Contact