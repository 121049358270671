// Dependencies //
import React from "react"
import { Tab, Nav, Row, Col, Card, Accordion } from "react-bootstrap"

// Stylesheet //
import Styles from "../sass/components/services.module.sass"

const Services = () => {
  return (
    <div>
      <div className={Styles["services--desktop"]}>
        <Tab.Container defaultActiveKey="first">
          <Row>
            <Col sm={4}>
              <Nav className="flex-column">
                <Nav.Item className={Styles["services__list-item--desktop"]}>
                  <Nav.Link eventKey="first">Tilpasset design</Nav.Link>
                </Nav.Item>
                <Nav.Item className={Styles["services__list-item--desktop"]}>
                  <Nav.Link eventKey="second">Prototyping</Nav.Link>
                </Nav.Item>
                <Nav.Item className={Styles["services__list-item--desktop"]}>
                  <Nav.Link eventKey="third">Profilering</Nav.Link>
                </Nav.Item>
                <Nav.Item className={Styles["services__list-item--desktop"]}>
                  <Nav.Link eventKey="fourth">Personlaliserte gaver</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={8}>
              <Tab.Content className={Styles["services__description--desktop"]}>
                <Tab.Pane eventKey="first">
                  <h2>Håndlaget, skreddersydd design:</h2>
                  <p>
                    Alle våre design er skreddersydd til den enkeltes behov. Vår lange erfaring innen produktdesign,produksjon, materialkunnskap og mye annet, gjør oss godt egnet til å forutse og håndtere alt fra ergonomiske krav, til uforutsette utfordringer i hver enkelt situasjon.
                    <span className={Styles["spacer"]}/>
                    Vi bruker en kombinasjon av laserkuttere, datastyrte freser, 3D-printing og godt, gammeldags håndtverk til å utforme en stor rekke produkter- fra fuglehus og byggesett, til hagemøbler, skilt og unike interiørobjekter.
                  </p>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <h2>Prototyping og designfaglig rådgiving:</h2>
                  <p>
                    Vi goblins er veldig glad i nye og spennende oppfinnelser, og har samarbeidet med flere smarte sørlendinger i deres søken mot å virkeliggjøre deres idéer.
                    <span className={Styles["spacer"]}/>
                    Vår maskinpark og faglige tyngde gjør oss velegnet til å produsere prototyper på nesten alle stadier, enten det er i plast, tre, papp eller andre materialer.
                    <span className={Styles["spacer"]}/>
                    Vi tilbyr også rådgiving innen prototyping og design, og samarbeider jevnlig med spirende designere ved å guide dem gjennom en strukturert og målrettet prototyping- og designprosess, samt råd innenfor strukturering og fremtidig masseproduksjon av et ferdigutviklet produkt.
                    <span className={Styles["spacer"]}/>
                    Vi samarbeider også med kunstnere for å produsere små-og storskalaprototyper, med arkitekter og entreprenører, og tilbyr produksjon av bygning- og landskapsmodeller, arealprototyper og digital visualisering.
                  </p>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <h2>Profilering og firmagaver:</h2>
                  <p>
                    Flere og flere bedrifter ser verdien av å assosieres med godt og kreativt, lokalt håndverk i deres profileringsvalg, gaver og interiørdekor.
                    <span className={Styles["spacer"]}/>
                    Enten du trenger et skilt for å vise hvor bedriften din ligger, hva du gjør eller hvem du er, vi kan fikse det. Trenger du en pokal eller premie å dele ut til en eller flere spesielle folk? Vi fikser det!
                    <span className={Styles["spacer"]}/>
                    Våre produkter finnes hos alle lokale bedrifter med god, sofistikert smak, som drives av ekstra kjekke mennesker.
                    <span className={Styles["spacer"]}/>
                    Vi tilbyr innovative, skreddersydde produkter, formgitt med omtanke og stolthet- alt lokalt i Kristiansand.
                  </p>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <h2>Personlige, spesielle gaver:</h2>
                  <p>
                    Kjenner du noen som har bursdag, og fortjener en bursdagskrone i tøyelig tre? Skal du ha bryllup og ønsker eksklusivt håndlaget dekor, bordskånere, navneskilt, eller mer? Er barna dine så kule at de fortjener egne, helt spesielle barneromskilt?
                    <span className={Styles["spacer"]}/>
                    Her skinner vår goblin-glede igjennom, for vi elsker å lage fine ting i begrenset opplag. Trenger du en unik gave, kan vi designe og lage for deg helt fra scratch! (eller du kan velge blant flere fine kombinasjoner vi har forberedt- valget er ditt!)
                  </p>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
     
      <div className={Styles["services--mobile"]}>
        <Accordion>
          <Card className={Styles["services__list-item--mobile"]}>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Tilpasset design
            </Accordion.Toggle>
            <Accordion.Collapse className={Styles["services__description--mobile"]} eventKey="0">
              <Card.Body>
                <h2>Håndlaget, skreddersydd design:</h2>
                <p>
                  Alle våre design er skreddersydd til den enkeltes behov. Vår lange erfaring innen produktdesign,produksjon, materialkunnskap og mye annet, gjør oss godt egnet til å forutse og håndtere alt fra ergonomiske krav, til uforutsette utfordringer i hver enkelt situasjon.
                  <span className={Styles["spacer"]}/>
                  Vi bruker en kombinasjon av laserkuttere, datastyrte freser, 3D-printing og godt, gammeldags håndtverk til å utforme en stor rekke produkter- fra fuglehus og byggesett, til hagemøbler, skilt og unike interiørobjekter.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className={Styles["services__list-item--mobile"]}>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Prototyping
            </Accordion.Toggle>
            <Accordion.Collapse className={Styles["services__description--mobile"]} eventKey="1">
              <Card.Body>
                <h2>Prototyping og designfaglig rådgiving:</h2>
                <p>
                  Vi goblins er veldig glad i nye og spennende oppfinnelser, og har samarbeidet med flere smarte sørlendinger i deres søken mot å virkeliggjøre deres idéer.
                  <span className={Styles["spacer"]}/>
                  Vår maskinpark og faglige tyngde gjør oss velegnet til å produsere prototyper på nesten alle stadier, enten det er i plast, tre, papp eller andre materialer.
                  <span className={Styles["spacer"]}/>
                  Vi tilbyr også rådgiving innen prototyping og design, og samarbeider jevnlig med spirende designere ved å guide dem gjennom en strukturert og målrettet prototyping- og designprosess, samt råd innenfor strukturering og fremtidig masseproduksjon av et ferdigutviklet produkt.
                  <span className={Styles["spacer"]}/>
                  Vi samarbeider også med kunstnere for å produsere små-og storskalaprototyper, med arkitekter og entreprenører, og tilbyr produksjon av bygning- og landskapsmodeller, arealprototyper og digital visualisering.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className={Styles["services__list-item--mobile"]}>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              Profilering
            </Accordion.Toggle>
            <Accordion.Collapse className={Styles["services__description--mobile"]} eventKey="2">
              <Card.Body>
                <h2>Profilering og firmagaver:</h2>
                <p>
                  Flere og flere bedrifter ser verdien av å assosieres med godt og kreativt, lokalt håndverk i deres profileringsvalg, gaver og interiørdekor.
                  <span className={Styles["spacer"]}/>
                  Enten du trenger et skilt for å vise hvor bedriften din ligger, hva du gjør eller hvem du er, vi kan fikse det. Trenger du en pokal eller premie å dele ut til en eller flere spesielle folk? Vi fikser det!
                  <span className={Styles["spacer"]}/>
                  Våre produkter finnes hos alle lokale bedrifter med god, sofistikert smak, som drives av ekstra kjekke mennesker.
                  <span className={Styles["spacer"]}/>
                  Vi tilbyr innovative, skreddersydde produkter, formgitt med omtanke og stolthet- alt lokalt i Kristiansand.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className={Styles["services__list-item--mobile"]}>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              Personaliserte gaver
            </Accordion.Toggle>
            <Accordion.Collapse className={Styles["services__description--mobile"]} eventKey="3">
              <Card.Body>
                <h2>Håndlaget, skreddersydd design:</h2>
                <p>
                  Alle våre design er skreddersydd til den enkeltes behov. Vår lange erfaring innen produktdesign,produksjon, materialkunnskap og mye annet, gjør oss godt egnet til å forutse og håndtere alt fra ergonomiske krav, til uforutsette utfordringer i hver enkelt situasjon.
                  <span className={Styles["spacer"]}/>
                  Vi bruker en kombinasjon av laserkuttere, datastyrte freser, 3D-printing og godt, gammeldags håndtverk til å utforme en stor rekke produkter- fra fuglehus og byggesett, til hagemøbler, skilt og unike interiørobjekter.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  )
}

export default Services