// Dependencies //
import React from "react"
import { Row, Col } from "react-bootstrap"

// Components //
import Bread from "../components/Bread"

// Assets //
import portrait_bojan from "../images/portraits/portrait_bojan.png"
import portrait_cassiana from "../images/portraits/portrait_cassiana.png"
import portrait_sasha from "../images/portraits/portrait_sasha.png"
import portrait_tram from "../images/portraits/portrait_tram.png"
import portrait_maximilian from "../images/portraits/portrait_maximilian.png"

// Stylesheet //
import Styles from "../sass/pages/about.module.sass"

const AboutPage = () => {
  return (
    <div className={Styles["container"]}>
      <Bread pageTitle={"om oss"} imageModifier={"about"}/>
      <div className={Styles["content-well"]}>
        <div className={Styles["about"]}>
          <p className={Styles["about__text"]}>
            Hobbygoblins AS er et samarbeid mellom håndverkere, designere og kunstnere, basert i Kristiansand!
            Samlet har vi årelang erfaring innen design, digital- og manuell produksjon, research og produktutvikling.
            <span className={Styles["spacer"]}/>
            Hobbygoblins er et resultat av skaperglede, ambisjoner og mangeårig kompetanse innen et stort antall områder. Vi startet som en gruppe venner som møttes via drifts- og frivillig arbeid ved Makerspace Norway, og innså at med våre samlede krefter, var det få grenser for hva vi kunne skape.
          </p>
        </div>
        <div className={Styles["creators"]}>
          <h1 className={Styles["creators__heading"]}>Skaperne</h1>
          <Row>
            <Col md={6} className={Styles["creators__creator"]}>
              <Row noGutters={true}>
                <Col sm={4} md={3} className={Styles["creators__creator__image"]}>
                  <img src={portrait_bojan} alt="Bojan Milinkovic"/>
                </Col>
                <Col sm={8} md={9} className={Styles["creators__creator__text"]}>
                  <div>
                    <span>Bojan Milinkovic</span>
                    <p>3D-designer</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6} className={Styles["creators__creator"]}>
              <Row noGutters={true}>
                <Col sm={4} md={3} className={Styles["creators__creator__image"]}>
                  <img src={portrait_cassiana} alt="Cassiana Martins"/>
                </Col>
                <Col sm={8} md={9} className={Styles["creators__creator__text"]}>
                  <div>
                    <span>Cassiana Martins</span>
                    <p>Laseroperatør, administrasjon</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6} className={Styles["creators__creator"]}>
              <Row noGutters={true}>
                <Col sm={4} md={3} className={Styles["creators__creator__image"]}>
                  <img src={portrait_sasha} alt="Sasha Milinkovic"/>
                </Col>
                <Col sm={8} md={9} className={Styles["creators__creator__text"]}>
                  <div>
                    <span>Sasha Milinkovic</span>
                    <p>Kunstner</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6} className={Styles["creators__creator"]}>
              <Row noGutters={true}>
                <Col sm={4} md={3} className={Styles["creators__creator__image"]}>
                  <img src={portrait_tram} alt="Tram Nguyen"/>
                </Col>
                <Col sm={8} md={9} className={Styles["creators__creator__text"]}>
                  <div>
                    <span>Tram Nguyen</span>
                    <p>DAK2D-designer</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6} className={Styles["creators__creator"]}>
              <Row noGutters={true}>
                <Col sm={4} md={3} className={Styles["creators__creator__image"]}>
                  <img src={portrait_maximilian} alt="Maximilian Dybvik"/>
                </Col>
                <Col sm={8} md={9} className={Styles["creators__creator__text"]}>
                  <div>
                    <span>Maximilian Dybvik</span>
                    <p>Utvikler, operatør- laser og fres</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default AboutPage