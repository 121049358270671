// Dependencies //
import React from 'react'
import { HashRouter as Router, Switch, Route } from "react-router-dom"

// Components //
import Header from "../components/Header"
import Footer from "../components/Footer"
import Contact from "../components/Contact"

// Pages //
import HomePage from "../pages/HomePage"
import ServicesPage from "../pages/ServicesPage"
// import ProjectsPage from "../pages/ProjectsPage"
import AboutPage from "../pages/AboutPage"

// Stylesheets //
import "../sass/base/main.sass"
import Styles from "../sass/components/layout.module.sass"

const Layout = () => {
  return (
    <Router>
      <div className={Styles["container"]}>
        <div className={Styles["content"]}>
          <Header/>
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/tjenester" component={ServicesPage} />
            {/* <Route path="/prosjekter" component={ProjectsPage} /> */}
            <Route path="/om-oss" component={AboutPage} />
          </Switch>
        </div>
        <Contact/>
        <Footer/>
      </div>
    </Router>
  )
}

export default Layout