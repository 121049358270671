// Dependencies //
import React from "react"

// Stylesheet //
import Styles from "../sass/components/bread.module.sass"

const Bread = (props) => {
  return (
    <div className={`${Styles["bread"]} ${Styles["bread--" + props.imageModifier]}`}>
      <h1 className={Styles["bread__heading"]}>
        {props.pageTitle}
      </h1>
      <h2 className={Styles["bread__crumbs"]}>
        Hjem / {props.pageTitle}
      </h2>
    </div>
  )
}

export default Bread