// Dependencies //
import React, { useState } from "react"
import { Link } from "react-router-dom"

// Assets //
import Logo from "../images/logo_light.svg"

// Stylesheet //
import Styles from "../sass/components/header.module.sass"

const Header = () => {
  const [MenuToggled, setMenuToggled] = useState(false)

  const MenuToggle = () => {
    setMenuToggled(!MenuToggled)
  }

  return (
    <header className={Styles["header"]}>
      <div className={Styles["header__content-well"]}>
        <Link to="/">
          <img className={Styles["header__logo"]} src={Logo} alt="Hobbygoblins logo" />
        </Link>

        <nav className={`${Styles["header__nav"]} ${Styles["header__nav--desktop"]}`}>
          <Link to="/">Hjem</Link>
          <Link to="/tjenester">Tjenester</Link>
          {/* <Link to="/prosjekter">Prosjekter</Link> */}
          <Link to="/om-oss">Om oss</Link>
        </nav>

        <div onClick={MenuToggle} className={`${Styles["header__menu-toggle"]} ${MenuToggled ? Styles["header__menu-toggle--toggled"] : ""}`}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div onClick={MenuToggle} className={`${Styles["header__nav--mobile__overlay"]} ${MenuToggled ? Styles["header__nav--mobile__overlay--active"] : ""}`}>
          <nav className={`${Styles["header__nav"]} ${Styles["header__nav--mobile"]}`}>
            <Link onClick={MenuToggle} to="/">Hjem</Link>
            <Link onClick={MenuToggle} to="/tjenester">Tjenester</Link>
            {/* <Link onClick={MenuToggle} to="/prosjekter">Prosjekter</Link> */}
            <Link onClick={MenuToggle} to="/om-oss">Om Oss</Link>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header