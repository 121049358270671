// Dependencies //
import React from "react"
import { Link } from "react-router-dom"

// Assets //
import Logo from "../images/logo_dark.svg"
import social_facebook from "../images/social/social_facebook.svg"
import social_instagram from "../images/social/social_instagram.svg"

// Stylesheet //
import Styles from "../sass/components/footer.module.sass"

const Footer = () => {
  return (
    <footer className={Styles["footer"]}>
      <div className={Styles["content-well"]}>
      <div className={Styles["flex-container"]}>
        <div className={Styles["company-details"]}>
          <img className={Styles["company-details__logo"]} src={Logo} alt="Hobbygoblins logo" />
          <div className={Styles["company-details__info"]}>
            <h2>Hobbygoblins AS</h2>
            <h4>Vestre Strandgate 19B</h4>
            <h4>4611 Kristiansand</h4>
            <h4>Orgnr: 921 788 967</h4>
            <h4>Copyright Hobbygoblins AS 2019</h4>
          </div>
        </div>
        <nav className={Styles["footer-nav"]}>
          <Link to="/">Hjem</Link>
          <Link to="/tjenester">Tjenester</Link>
          {/* <Link to="/prosjekter">Prosjekter</Link> */}
          <Link to="/om-oss">Om Oss</Link>
          <div className={Styles["social-media"]}>
            <a href="https://www.facebook.com/HobbygoblinsNorway/" target="_blank" rel="noopener noreferrer">
              <img src={social_facebook} alt="facebook"/>
            </a>
            <a href="https://www.instagram.com/hobbygoblinsnorway/" target="_blank" rel="noopener noreferrer">
              <img src={social_instagram} alt="instagram"/>
            </a>
          </div>
        </nav>
      </div>
      </div>
    </footer>
  )
}

export default Footer