// Dependencies //
import React from "react"

// Components //
import Bread from "../components/Bread"
import Services from "../components/Services"
import Clients from "../components/Clients"

// Assets //
import ServicesImage from "../images/services/open-desk.jpg"

// Stylesheet //
import Styles from "../sass/pages/services.module.sass"

const ServicesPage = () => {
  return (
    <div className={Styles["container"]}>
      <Bread pageTitle="tjenester" imageModifier="services"/>
      <div className={Styles["content-well"]}>
        <img className={Styles["services__image"]} src={ServicesImage} alt="open-desk prosjekt"/>
        <Services/>
        <Clients/>
      </div>
    </div>
  )
}

export default ServicesPage