// Dependencies //
import React from "react"
import { Row, Col } from "react-bootstrap"

// Assets //
import client_minimakers from "../images/clients/client_minimakers.svg"
import client_noracta from "../images/clients/client_noracta.png"
import client_ntGruppen from "../images/clients/client_ntGruppen.png"
import client_scanflex from "../images/clients/client_scanflex.svg"
import client_bilagos from "../images/clients/client_bilagos.png"
import client_tilStede from "../images/clients/client_tilStede.png"

// Stylesheet //
import Styles from "../sass/components/clients.module.sass"

const Clients = () => {
  return (
    <section className={Styles["our-clients"]}>
      <h1 className={Styles["our-clients__heading"]}>Våre kunder</h1>
      <Row className={Styles["our-clients__logos"]}>
        <Col md={2} sm={3} xs={4}>
          <img className={Styles["our-clients__logos__logo"]} src={client_minimakers} alt="minimakers"/>
        </Col>
        <Col md={2} sm={3} xs={4}>
          <img className={Styles["our-clients__logos__logo"]} src={client_noracta} alt="noracta"/>
        </Col>
        <Col md={2} sm={3} xs={4}>
          <img className={Styles["our-clients__logos__logo"]} src={client_ntGruppen} alt="nt gruppen"/>
        </Col>
        <Col md={2} sm={3} xs={4}>
          <img className={Styles["our-clients__logos__logo"]} src={client_scanflex} alt="scanflex"/>
        </Col>
        <Col md={2} sm={3} xs={4}>
          <img className={Styles["our-clients__logos__logo"]} src={client_bilagos} alt="bilagos"/>
        </Col>
        <Col md={2} sm={3} xs={4}>
          <img className={Styles["our-clients__logos__logo"]} src={client_tilStede} alt="til stede, mat og mer"/>
        </Col>
      </Row>
    </section>
  )
}

export default Clients