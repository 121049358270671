// Dependencies //
import React from "react"
// import { Link } from "react-router-dom"
import { Row, Col } from "react-bootstrap"

// Components //
import Carousel from "../components/Carousel"
import Services from "../components/Services"

// Assets //
import Client_Kristiansand from "../images/clients/client_kristiansand.svg"
import Client_Minimakers from "../images/clients/client_minimakers.svg"
import Client_Bilagos from "../images/clients/client_bilagos.png"
import Client_Hobbygoblins from "../images/logo_dark.svg"

import Project_Kristiansand from "../images/projects/project_kristiansand.jpg"
import Project_Minimakers from "../images/projects/project_minimakers.jpg"
import Project_Bilagos from "../images/projects/project_bilagos.jpg"
import Project_Dragonship from "../images/projects/project_dragonship.jpg"
import Project_Sign from "../images/projects/project_sign.jpg"
import Project_Coaster from "../images/projects/project_coaster.jpg"

// Stylesheet //
import Styles from "../sass/pages/home.module.sass"

const HomePage = () => {
  return (
    <div className={Styles["container"]}>
      <Carousel />
      <div className={Styles["content-well"]}>
        <div className={Styles["services"]}>
          <h1 className={Styles["services__heading"]}>Tjenester</h1>
          <Services/>
        </div>
        <div className={Styles["projects"]}>
        <h1 className={Styles["projects__heading"]}>Prosjekter</h1>
        <div className={Styles["projects__brief"]}>
          <Row>
            <Col md={6}>
              <Row className={`${Styles["projects__project"]} ${Styles["projects__project-1"]}`} noGutters="true">
                <div className={Styles["projects__project-overlay"]}>
                  <span aria-hidden="true" className="lnr lnr-pointer-up" />
                </div>
                <Col className={Styles["projects__project__client-image"]} xs={4}>
                  <img src={Client_Kristiansand} alt="project" />
                </Col>
                <Col className={Styles["projects__project__project-image"]} xs={8}>
                  <img src={Project_Kristiansand} alt="project" />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className={`${Styles["projects__project"]} ${Styles["projects__project-2"]}`} noGutters="true">
                <div className={Styles["projects__project-overlay"]}>
                  <span aria-hidden="true" className="lnr lnr-pointer-up" />
                </div>
                <Col className={Styles["projects__project__client-image"]} xs={4}>
                  <img src={Client_Minimakers} alt="project" />
                </Col>
                <Col className={Styles["projects__project__project-image"]} xs={8}>
                  <img src={Project_Minimakers} alt="project" />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={`${Styles["projects__project"]} ${Styles["projects__project-3"]}`} noGutters="true">
            <Col className={Styles["projects__project__text"]} xs={8}>
              <p>Navneskilt er moro å lage, hyggelig å gi bort og gøyalt å ha. Vi har laget mange fine navneskilt både etter ønske og visjon fra kunde og med frie tøyler til å skape noe for dem.</p>
            </Col>
            <Col className={Styles["projects__project__project-image"]} xs={4}>
              <img src={Project_Sign} alt="project" />
            </Col>
          </Row>
          
          <Row>
            <Col md={6}>
              <Row className={`${Styles["projects__project"]} ${Styles["projects__project-4"]}`} noGutters="true">
                <div className={Styles["projects__project-overlay"]}>
                  <span aria-hidden="true" className="lnr lnr-pointer-up" />
                </div>
                <Col className={Styles["projects__project__client-image"]} xs={4}>
                  <img src={Client_Hobbygoblins} alt="project" />
                </Col>
                <Col className={Styles["projects__project__project-image"]} xs={8}>
                  <img src={Project_Dragonship} alt="project" />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row className={`${Styles["projects__project"]} ${Styles["projects__project-5"]}`} noGutters="true">
                <div className={Styles["projects__project-overlay"]}>
                  <span aria-hidden="true" className="lnr lnr-pointer-up" />
                </div>
                <Col className={Styles["projects__project__client-image"]} xs={4}>
                  <img src={Client_Bilagos} alt="project" />
                </Col>
                <Col className={Styles["projects__project__project-image"]} xs={8}>
                  <img src={Project_Bilagos} alt="project" />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={`${Styles["projects__project"]} ${Styles["projects__project-6"]}`} noGutters="true">
            <Col className={Styles["projects__project__project-image"]} xs={4}>
              <img src={Project_Coaster} alt="project" />
            </Col>
            <Col className={Styles["projects__project__text"]} xs={8}>
              <p>Graverte skifer som bordskånere. Vi har laget en rekke ulike motiver og logoer, men mest etterspurt har vært bordskånere med fotballklubb logo.</p>
            </Col>
          </Row>
        </div>
        {/* <Link to="/prosjekter" className={Styles["projects__button"]}>Se mer</Link> */}
      </div>
      </div>      
    </div>
  )
}

export default HomePage