// Dependencies //
import React from "react"

// Stylesheet //
import Styles from "../sass/components/carousel.module.sass"

const Carousel = () => {
  return (
    <section className={Styles["carousel"]}>
        <div className={Styles["carousel__content"]}>
          <h1>Hobbygoblins AS</h1>
          <h2>Et resultat av skaperglede, ambisjoner og mangeårig kompetanse innen produktdesign, produksjon, materialkunnskap og mye annet.</h2>
        </div>
    </section>
  )
}

export default Carousel